import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import MikeWiseContact from "../components/mikewisecontactus"
import LynneMarlorContact from "../components/lynnemarlorcontactus"
import SamNathanContact from "../components/samnathancontactus"
import HeatherLoweContact from "../components/heatherlowecontactus"
import EricRouxContact from "../components/ericrouxcontactus"
import JohnHargravesContact from "../components/johnhargravescontactus"
import DougMehneContact from "../components/dougmehnecontactus"
import AJDeboleContact from "../components/ajdebolecontactus"
import Contactus from "../components/contactus"


export default function ContactTemplate() {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false)
  const [isOverlayVisibleLM, setIsOverlayVisibleLM] = useState(false)
  const [isOverlayVisibleSN, setIsOverlayVisibleSN] = useState(false)
  const [isOverlayVisibleHL, setIsOverlayVisibleHL] = useState(false)
  const [isOverlayVisibleER, setIsOverlayVisibleER] = useState(false)
  const [isOverlayVisibleJH, setIsOverlayVisibleJH] = useState(false)
  const [isOverlayVisibleDM, setIsOverlayVisibleDM] = useState(false)
  const [isOverlayVisibleAD, setIsOverlayVisibleAD] = useState(false)

  const handleToggleOverlay = () => {
    setIsOverlayVisible(!isOverlayVisible)
  }		
  const handleToggleOverlayLM = () => {
    setIsOverlayVisibleLM(!isOverlayVisibleLM)
  }		
  const handleToggleOverlaySN = () => {
    setIsOverlayVisibleSN(!isOverlayVisibleSN)
  }		
  const handleToggleOverlayHL = () => {
    setIsOverlayVisibleHL(!isOverlayVisibleHL)
  }	
  const handleToggleOverlayER = () => {
    setIsOverlayVisibleER(!isOverlayVisibleER)
  }		
  const handleToggleOverlayJH = () => {
    setIsOverlayVisibleJH(!isOverlayVisibleJH)
  }		
  const handleToggleOverlayDM = () => {
    setIsOverlayVisibleDM(!isOverlayVisibleDM)
  }		
  const handleToggleOverlayAD = () => {
    setIsOverlayVisibleAD(!isOverlayVisibleAD)
  }		

  return (
    <Layout breadcrumbs={["Contact Us"]}>
    <div className="container-flex">
      <div className="contact-us-box-content">
        <h2 className="contact-us-header">Contact Us</h2>
        <p className="contact-us-content">If you have questions, feedback, or want to inquire about partnerships, drop us a line. We're always eager to connect and discuss all things blockchain.</p>
      </div>
      <div className="contact-us-box-column">
        <div className="contact-us">
        <Contactus />
       </div>
      </div>
    </div>    
      </Layout>
  )
}